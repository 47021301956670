import {OptionsChecker} from "@thomas-inst/optionschecker";


const couldNotLoginMessage = "Could not log in, please try again!"

export class LoginWidget {

  constructor(options = {}) {
    const optionsSpec = {
      containerSelector: {type: 'string'},
      login: {
        type: 'function',
        default: (user, pwd) => {
          return new Promise((resolve) => {
            console.log(`Default login function for ${user}, returning false`)
            resolve(false)
          })
        }
      },
      onLoginSuccess: {
        type: 'function',
        default: (user) => { console.log(`Login successful for user ${user}`)}
      },
      startActive: {
        type: 'bool',
        default: true
      },
      debug: {
        type: 'bool',
        default: false
      }
    }
    let oc = new OptionsChecker({ context: 'LoginWidget', optionsDefinition: optionsSpec})
    let cleanOptions = oc.getCleanOptions(options)
    this.onLoginSuccess = cleanOptions.onLoginSuccess
    this.login = cleanOptions.login
    this.containerSelector = cleanOptions.containerSelector
    this.debug = cleanOptions.debug
    this.container = $(this.containerSelector)
    this.isActive = false
    this.loggingIn = false
    this.user = ''
    if (cleanOptions.startActive) {
      this.activate()
    }

  }

  activate() {
    this.container.html(this.__getWidgetHtml())
    this.loginBtn = $(`${this.containerSelector} .loginBtn`)
    this.userInput = $( `${this.containerSelector} .userInput`)
    this.passwordInput = $( `${this.containerSelector} .passwordInput`)
    this.infoSpan = $( `${this.containerSelector} .infoSpan`)
    this.loginBtn.on('click', this.__genOnClickLoginButton())
    this.isActive = true
    this.loggingIn = false
  }

  deactivate(erase = true) {
    if (erase) {
      this.container.html('')
    }
    this.isActive = false
    this.loggingIn = false
  }

  __genOnClickLoginButton() {
    return () => {
      if (!this.isActive) {
        this.debug && console.log(`Click on login button on inactive widget`)
        return
      }
      let user = this.__sanitizeString(this.userInput.val())
      let pwd = this.__sanitizeString(this.passwordInput.val())
      if (user === '' || pwd === '') {
        this.debug && console.log(`Click on login button with empty user or password`)
        return
      }
      this.loggingIn = true
      this.user = user
      this.infoSpan.html(`Checking password...`)
      this.login(user, pwd).then( (loginSuccessful) => {
        this.debug && console.log(`Login successful = ${loginSuccessful}`)
        if (!this.isActive || !this.loggingIn) {
          this.debug && console.log(`Widget was deactivated while checking the password`)
          return
        }
        if (loginSuccessful) {
          this.infoSpan.html(`Login OK...`)
          this.onLoginSuccess(this.user)
          //this.infoSpan.html('')
          this.loggingIn = false
        } else {
          this.infoSpan.html(couldNotLoginMessage)
          this.loggingIn = false
          setTimeout( () => {
            this.infoSpan.html('')
          }, 1000)
        }
      }).catch( () => {
        this.infoSpan.html(couldNotLoginMessage)
        this.loggingIn = false
      })
    }
  }

  __sanitizeString(user) {
    return user.replace(/^\s+/, '').replace(/\s+$/, '')
  }

  __getWidgetHtml() {
    return `User: <input type="text" class="userInput">
            Password: <input type="password" class="passwordInput">
            <button class="btn btn-sm btn-outline-secondary loginBtn">Login</button>
            <span class="infoSpan text-danger"></span>
     `
  }

}