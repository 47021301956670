import {DateTime} from "luxon";

export class TimeString {

  /**
   *
   * @param {string}timestring
   * @return {string}
   */
  static getDateAndTimeString(timestring) {
    let dt = DateTime.fromSQL(timestring)
    return dt.toFormat('dd LLL yyyy, H:mm:ss')
  }

  /**
   *
   * @param {string}timestring
   * @return {string}
   */
  static getDateString(timestring) {
    let dt = DateTime.fromSQL(timestring)
    return dt.toFormat('dd LLL yyyy')
  }
}