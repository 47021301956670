import {TimeString} from "../toolbox/TimeString";
import {LoginWidget} from "../widgets/LoginWidget";


export class HomePage {

  constructor(docArray, baseUrl, userId) {
    console.log('Bilderberg2: HomePage')
    this.docs = docArray
    this.baseUrl = baseUrl
    this.userId = userId

    console.log(`${this.docs.length} documents in the system`)
    $('#content').html(this._getHtml())
    $('#login-area').html(this._getLoginAreaHtml())

    if (this.userId === '') {
      new LoginWidget({
        containerSelector: '#loginDiv',
        debug: true,
        login: this.__genLoginFunction(),
        onLoginSuccess: (user) => {
          $('#login-area').html(`Login OK, reloading...`)
          window.location.reload()
        }
      })
    } else {
      $('#logoutBtn').on('click', this.__genOnClickLogoutButton())
    }


  }

  __genOnClickLogoutButton() {
    return () => {
      $('#login-area').html('Logging out...')
      $.get(this.__getApiLogoutUrl()).then( (resp) => {
        console.log(`Logout response`)
        console.log(resp)
        console.log('Reloading page')
        window.location.reload()
      })
    }
  }

  __genLoginFunction() {
    return (user, pwd) => {
      return new Promise( resolve => {
          $.post(this.__getApiLoginUrl(), {
            user: user,
            pwd: pwd
          }).then( (resp) => {
            console.log(`Got response from server`)
            console.log(resp)
            resolve(resp['loggedIn'])
          }).catch( () => {
            resolve(false)
          })
      })
    }
  }

  __getApiLoginUrl() {
    return `${this.baseUrl}/api/login`
  }

  __getApiLogoutUrl() {
    return `${this.baseUrl}/api/logout`
  }

  _getHtml() {
    let publicDocs = this.docs.filter( (doc) => {
      return doc['isPublic']
    })
    let html = ''
    if (this.userId === '') {
      html += `<p>There are ${publicDocs.length} public documents in the system</p>`
      html += `<table class="doc-table"><tr><th>ID</th><th>Page Count</th><th>Created</th></tr>`
      publicDocs.forEach( (doc) => {
        html += `<tr>
            <td><a href="${this.__getDocUrl(doc['id'])}">${doc['id']}</a></td>
            <td>${doc['pageCount']}</td>
            <td>${TimeString.getDateString(doc['creationTime'])}
`
      })
      html += '</table>'
    } else {
      html += `<p>There are ${this.docs.length} documents in the system, ${publicDocs.length} public, ${this.docs.length -publicDocs.length } 
    private, with ${this.calcTotalPageCount(this.docs)} pages in total.</p>`
      html += `<table class="doc-table"><tr><th>ID</th><th>Page Count</th><th>Created</th></tr>`
      this.docs.forEach( (doc) => {
        html += `<tr>
            <td><a href="${this.__getDocUrl(doc['id'])}">${doc['id']}</a></td>
            <td>${doc['pageCount']}</td>
            <td>${TimeString.getDateString(doc['creationTime'])}</td>
            <td>${doc['isPublic'] ? '' : 'Private'}</td>
            <td>${doc['migrated'] ? '' : 'To migrate'}</td>
`
      })
      html += '</table>'
    }
    return html
  }

  _getLoginAreaHtml() {
    if (this.userId === '') {
      return `<div id="loginDiv"></div>`
    }
    let userInfoHtml = this.userId
    // return `<div>${userInfoHtml} <button class="btn btn-sm btn-outline-secondary" id="logoutBtn">Logout</button></div>`
    return ` 
 
 <div class="dropdown">
 ${userInfoHtml}
  <a class="btn btn-outline dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="bi bi-caret-down-fill"></i>
  </a>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
    <li><a class="dropdown-item" id="changePasswdBtn" href="${this.baseUrl}/password/change">Change Password</a></li>
    <li><a class="dropdown-item" id="logoutBtn" href="#">Logout</a></li>
  </ul>
</div>
`

  }

  __getDocUrl(id) {
    return `${this.baseUrl}/${id}`
  }

  calcTotalPageCount(docArray) {
    let total = 0;
    docArray.forEach((doc) => {
      total += doc['pageCount']
    })
    return total
  }
}

window.HomePage = HomePage